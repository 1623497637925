<template>
    <div class="selectOrderPop" v-loading="loading">
        <div class=" table-bg">
            <div class="table-header">
                <div class="el_left">
                    <el-button size="mini" @click="confirmSelection">
                        确认选择
                    </el-button>
                    <el-select
                        style="margin-left: 0.2rem"
                        v-model="filterParams.product_status"
                        placeholder="选择状态"
                        @change="changeStatus"
                    >
                        <el-option :value="0" label="全部">
                        </el-option>
                        <el-option :value="1" label="待生产">
                        </el-option>
                        <el-option :value="2" label="生产中">
                        </el-option>
                    </el-select>
                </div>
                <div class="el_right">
                    <div class="el_left conditionQuery">
                        <el-input placeholder="请输入搜索内容..." v-model="filterParams.search" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                        </el-input>
                    </div>
                </div>
            </div>
            <el-table
                border
                stripe
                :data="tableList"
                ref="multipleTable"
                @row-click="selateTable"
                :highlight-current-row="true"
                @selection-change="handleSelectionChange"
            >
                <el-table-column
                    key="select"
                    type="selection"
                    width="55"
                    v-if="checkType=='checkbox'"
                >
                </el-table-column>
                <el-table-column
                    label="选择"
                    width="55"
                    v-if="checkType=='radio'"
                >
                    <template slot-scope="scope">
                        <el-radio
                            v-model="radio"
                            :label="scope.row"
                        >
                        </el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="备注"
                    width="150"
                >
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="颜色"
                    width="50"
                >
                    <template slot-scope="scope">
                        <span class="color" :style="{height:'0.2rem',background: scope.row.block_cz_color}"></span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="task_number"
                    label="任务单号"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="customer_name"
                    label="客户名称"
                    width="150"
                >
                </el-table-column>
                <el-table-column
                    prop="sgdw_name"
                    label="施工单位"
                    width="150"
                >
                </el-table-column>

                <el-table-column
                    prop="project_name"
                    label="工程名称"
                    width="200"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    prop="place_detail"
                    label="施工部位"
                    width="150"
                >
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="产品标号"
                    width="150"
                >
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.strength_grade_name+' '+scope.row.special_require_name }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="arrival_time"
                    label="到货时间"
                    width="160"
                >
                </el-table-column>
                <el-table-column
                    prop="pouring_name"
                    label="浇筑方式"
                    width="140"
                >
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="泵车"
                    width="120"
                >
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.pump_plate_number_mark +' '+scope.row.pump_truck_name }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="quantity"
                    label="任务方量"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="grand_total_complete_quantity"
                    label="完成方量"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="xclxr"
                    label="联系人"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="xclxr_phone"
                    label="联系电话"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="employee_name"
                    label="业务员"
                    width="120"
                >
                </el-table-column>
            </el-table>
            <div class="table-page">
                <div class="el_right">
                    <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="filterParams.pageindex"
                        :page-sizes="[20,40,60,80,100]"
                        :page-size="filterParams.pagesize"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'select-order-pop',
    data() {
        return {
            loading: false,
            tableList: [],

            // 单选选择值
            radio: '',
            checkType: '',
            filterParams: {},

            total: 0,

            // 选中的数据
            selectData: [],
        };
    },
    props: {
        extr: Object,
    },
    created() {
        this.checkType = this.extr.checkType;
        this.filterParams = Object.assign({}, this.extr.filterParams);
        this.filterParams.pageindex = 1;
        this.filterParams.pagesize = 25;
        this.filterParams.product_status = 0;

        this.getList();
    },
    methods: {
        // 获取列表
        getList() {
            this.loading = true;
            this.$axios.$post('/interfaceApi/production/productpcb/multiple_tasks_page', this.filterParams)
                .then(res => {
                    this.total = res.total;
                    this.tableList = res.rows;
                }).finally(() => {
                    this.loading = false;
                });
        },
        // 变更筛选状态
        changeStatus() {
            this.getList();
        },
        selateTable(row) {
            if (this.checkType === 'checkbox') {
                this.$refs.multipleTable.toggleRowSelection(row);
                this.selectData = this.$refs.multipleTable.selection;
            } else if (this.checkType === 'radio') {
                this.radio = row;
                this.selectData = [row];
            }
        },
        handleSelectionChange() {
            this.selectData = this.$refs.multipleTable.selection;
        },

        confirmSelection() {
            if (this.selectData.length === 0) {
                this.$message.warning('请选择');
                return;
            }
            if (this.extr.selectCallBack) {
                this.extr.selectCallBack(this.selectData, this.checkType);
            }
            this.$parent.hide();
        },

        // 搜索
        search() {
            this.filterParams.pageindex = 1;
            this.filterParams.pagesize = 25;
            this.getList();
        },

        // 设置每页条数
        handleSizeChange(val) {
            this.filterParams.pagesize = val;
            this.getList();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.filterParams.pageindex = val;
            this.getTableData();
        },
    },
};
</script>

<style lang="stylus" scoped>
    .selectOrderPop
        width 100%
        height 100%
        padding 0.14rem
        background #fff

        .table-header
            height 0.51rem
            display flex
            justify-content space-between
            .el-select
                margin-left 0.2rem
                width 1.2rem
            .el-button
                color #fff
                /*background #2978ff*/
                background #1577d2
                padding 0 0.2rem
                border none
                line-height 0.36rem

            .conditionQuery
                width 3.6rem

                .el-input-group__append
                    background #1577d2
                    border 1px solid #1577d2

                    .el-button
                        color #fff

            .setUp
                .el-button
                    width 0.36rem
                    height 0.36rem
                    padding 0
                    color #fff
                    font-size 0.16rem
                    background #1577d2
                    margin-left 0.1rem

        .el-table
            height calc(100% - 0.95rem)

            th, td
                padding 0

                .cell
                    padding 0

            .editList
                > div
                    vertical-align top

                .table_input
                    width 100%
                    height 0.34rem
                    line-height 0.34rem
                    text-indent 0.14rem
                    border 1px solid #DCDFE6
                    border-radius 2px

                .el-date-editor
                    width 100% !important
</style>
